import React from 'react';
import {Button, Dialog, DialogActions} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import {fetchJson} from "../rest/fetch";
import {SERVER_IP} from "../index";
import CircularProgress from "@material-ui/core/CircularProgress";
import {translate} from 'react-admin';

class ResetAllDevicesDialog extends React.Component {
    state = {};

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({errorTitle: false});
    };


    handleSend = () => {
        this.setState({loading: true});
        fetchJson(`${SERVER_IP}/users/resetDevices`,
            {method: "PUT"})
            .then(() => {
                this.setState({loading: false, title: "", body: ""});
                this.props.onResponse(this.props.translate("resetAllView.done"), false);
            }, () => {
                this.setState({loading: false});
                this.props.onResponse(this.props.translate("resetAllView.failed"), false);
            })

    };

    render() {
        const {loading} = this.state;
        const {translate, open} = this.props;
        let title = translate("resetAllView.title");
        return (
            <Dialog open={open}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title">
                <DialogTitle
                    id="form-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {translate("resetAllView.message")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button disabled={loading}
                            variant={'text'} color={'primary'}
                            onClick={() => {
                                this.props.onResponse(null, false);
                            }}>
                        {translate("resetAllView.cancel")}
                    </Button>
                    <Button disabled={loading} variant={'text'}
                            color={'primary'}
                            onClick={this.handleSend}>
                        {loading && (
                            <CircularProgress size={25} thickness={2}/>
                        )}
                        {translate("resetAllView.yes")}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export const ResetAllDevicesViewTranslations = {
    en: {
        resetAllView: {
            title: "Reset All Devices",
            message: "Do you really want to reset all the devices for users?",
            cancel: "No",
            done: "Devices reset successful",
            failed: "Failed to reset devices",
            yes: "Yes",
        }
    },
    tr: {
        resetAllView: {
            title: "Reset All Devices",
            message: "Do you really want to reset all the devices for users?",
            cancel: "No",
            done: "Devices reset successful",
            failed: "Failed to reset devices",
            yes: "Yes",
        }
    }
}

export default translate(ResetAllDevicesDialog);