import {connect} from 'react-redux';
import {Layout} from 'react-admin';
import React from "react";
import Menu from './Menu';

export const lightTheme = {
    palette: {
        primary: {
            light: '#80e27e',
            main: '#4caf50',
            dark: '#087f23',
            contrastText: '#fff',
        },
        secondary: {
            light: '#80e27e',
            main: '#4caf50',
            dark: '#087f23',
            contrastText: '#fff',
        },
    },
};

const CustomLayout = props => (
    <Layout {...props}
            menu={Menu}/>
);
export default connect(
    state => ({
        theme: lightTheme,
    }),
    {}
)(CustomLayout);