import React from "react";
import {translate} from "react-admin";
import {DateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import enLocale from "date-fns/locale/en-US";
import trLocale from "date-fns/locale/tr";
import {locale} from "../App";

const localeMap = {
    en: enLocale,
    tr: trLocale,
};


export const DateTimePickerInput = translate(({translate, label, min, max, input, disabled, helperText}) => {
    if (input.value < 1000) {
        let date = new Date();
        input.onChange(date.getTime())
    }
    return <MuiPickersUtilsProvider
        utils={DateFnsUtils} locale={localeMap[locale()]}>
        <DateTimePicker
            disabled={disabled}
            value={input.value}
            maxDate={max}
            minDate={min}
            inputVariant={'filled'}
            label={translate(label)}
            ampm={false}
            helperText={helperText}
            format={"yyyy-MM-dd HH:mm:ss"}
            onChange={(date) => {
                input.onChange(date.getTime())
            }}
        />
    </MuiPickersUtilsProvider>
});