import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import {withRouter} from 'react-router-dom';

import {DashboardMenuItem, MenuItemLink, Responsive, translate, WithPermissions} from 'react-admin';
import {VideoIcon} from "../Video";
import UserIcon from '@material-ui/icons/AccountCircle';
import {CategoryIcon} from "../Category";
import {FaqIcon} from "../Faq";
import {ImageIcon} from "../Images";
import NotificationIcon from '@material-ui/icons/Notifications';
import {PdfIcon} from "../Pdf";
import {TextIcon} from "../Text";

const items = [
    {name: 'categories', icon: <CategoryIcon/>},
    {name: 'videos', icon: <VideoIcon/>},
    {name: 'pdfs', icon: <PdfIcon/>},
    {name: 'texts', icon: <TextIcon/>},
    {name: 'users', icon: <UserIcon/>},
    {name: "images", icon: <ImageIcon/>},
    {name: "faqs", icon: <FaqIcon/>},
    {name: 'notifications', icon: <NotificationIcon/>}
];

class Menu extends Component {
    static propTypes = {
        onMenuClick: PropTypes.func,
        logout: PropTypes.object,
    };
    state = {
        menuVehicles: false,
        users: false,
        menuReports: false,
    };

    handleToggle = menu => {
        this.setState(state => ({[menu]: !state[menu]}));
    };

    render() {
        const {onMenuClick, logout, translate} = this.props;
        return <div>
            <DashboardMenuItem onClick={onMenuClick}/>
            {items.map(item => (
                <MenuItemLink
                    key={item.name}
                    to={`/${item.name}`}
                    primaryText={translate(`resources.${item.name}.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={item.icon}
                    onClick={onMenuClick}
                />
            ))}
            <Responsive
                small={logout}
                medium={null}
            />
        </div>
    }
}

const mapStateToProps = state => {
    return {
        theme: state.theme,
    }
};

const enhance = compose(
    withRouter,
    connect(
        mapStateToProps,
        {}
    ),
    translate
);
const EnhancedMenu = enhance(Menu);
const PermissionMenu = (props) => (
    <WithPermissions render={({permissions}) => <EnhancedMenu {...props}
                                                              permissions={permissions}/>}
                     {...props}/>
);
export default PermissionMenu;