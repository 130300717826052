import React from "react";
import Icon from "@material-ui/icons/VideoLibrary";
import {Field} from 'react-final-form';
import {
    AutocompleteInput,
    BooleanField,
    BooleanInput,
    Create,
    Datagrid,
    DeleteWithConfirmButton,
    Edit,
    EditButton,
    Filter,
    FormTab,
    ImageField,
    List,
    maxLength,
    minLength,
    NumberInput,
    ReferenceField,
    ReferenceInput,
    required,
    SelectInput,
    Show,
    ShowButton,
    SimpleShowLayout,
    TabbedForm,
    TextField,
    TextInput
} from "react-admin";
import ImageUpload from "./common/ImageUpload";
import {TimeField} from "./common/TimeField";
import {categoryText, matchCategory, Types} from "./Category";

export const VideoIcon = Icon;
export const VideoCreate = (props) => (
    <Create {...props}>
        <CreateOrEdit/>
    </Create>
);

export const VideoEdit = (props) => (
    <Edit {...props}>
        <CreateOrEdit/>
    </Edit>
);
const VideoFilters = (props) => (
    <Filter {...props}>
        <TextInput source={'name'} alwaysOn/>
        <TextInput source={'description'} alwaysOn/>
        <BooleanField source={'featured'}/>
        <ReferenceInput source={"category.id"}
                        filterToQuery={text => ({description: text})}
                        reference={'categories'}>
            <AutocompleteInput
                matchSuggestion={matchCategory}
                optionText={categoryText}/>
        </ReferenceInput>
    </Filter>
);
export const VideoList = (props) => (
    <List {...props} bulkActionButtons={false}
          sort={{field: 'time', order: "DESC"}}
          filters={<VideoFilters/>}>
        <VideoGrid/>
    </List>
);

export const VideoGrid = (props) => (
    <Datagrid {...props}>
        <TextField source={'order'}/>
        <TextField source={"description"}/>
        {props.resource === "videos" && <ReferenceField source={"category.id"} reference={'categories'}>
            <TextField source={'name'}/>
        </ReferenceField>}
        <TextField source={"type"}/>
        <BooleanField source={'featured'}/>
        <TextField source={'url'}/>
        <TimeField source={"time"}/>
        <ShowButton/>
        <EditButton/>
        <DeleteWithConfirmButton/>
    </Datagrid>
);
export const VideoShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source={"name"}/>
            <TextField source={"description"}/>
            <BooleanField source={'featured'}/>
            <TextField source={"type"}/>
            <TextField source={'url'}/>
            <ImageField source={"thumb"}/>
            <ReferenceField source={"category.id"} reference={'categories'}>
                <TextField source={'description'}/>
            </ReferenceField>
            <TextField source={'order'}/>
            <TimeField source={"time"}/>
        </SimpleShowLayout>
    </Show>
);
const CreateOrEdit = (props) => (
    <TabbedForm {...props} redirect={"list"}>
        <FormTab label={"resources.videos.general"}>
            <TextInput source={"name"}
                       multiline
                       validate={[required(), maxLength(255)]}/>
            <TextInput source={"description"}
                       fullWidth
                       multiline
                       validate={[required(), maxLength(1000000)]}/>
            <ReferenceInput source={"category.id"}
                            allowEmpty
                            sort={{field: 'time', order: "DESC"}}
                            filterToQuery={text => ({description: text})}
                            reference={'categories'}>
                <AutocompleteInput
                    matchSuggestion={matchCategory}
                    optionText={categoryText}/>
            </ReferenceInput>
            <NumberInput source={"order"}
                         helperText={'resources.videos.fields.orderHelper'}
                         validate={required()}/>
            <TextInput source={'url'}
                       validate={[required(), minLength(5)]}
                       fullWidth
                       helperText={'resources.videos.fields.urlHelper'}/>
            <SelectInput source={"type"} choices={Types} validate={required()}/>
            <BooleanInput source={'featured'}/>
        </FormTab>
        <FormTab label={"resources.videos.fields.thumb"}>
            <Field name={"thumb"}
                   component={ImageUpload}
                   source={"thumb"}/>
        </FormTab>

    </TabbedForm>
);

export const VideoTranslations = {
    en: {
        videos: {
            name: "Videos",
            general: "General",
            fields: {
                name: "Title",
                thumb: "Thumbnail",
                description: "Description",
                url: "Video",
                category: {
                    id: "Category"
                },
                order: "Order",
                featured: "Featured",
                orderHelper: "Higher order will appear in bottom",
                urlHelper: "Input link from Google Drive or Dropbox https://sites.google.com/site/gdocs2direct/"
            }
        },
    },
    tr: {
        videos: {
            name: "Videoler",
            general: "General",
            fields: {
                name: "Title",
                thumb: "Thumbnail",
                description: "Description",
                url: "Video",
                category: {
                    id: "Category"
                },
                order: "Order",
                featured: "Featured",
                orderHelper: "Higher order will appear in bottom",
                urlHelper: "Input link from Google Drive or Dropbox"
            }
        },
    }
};