import React from 'react';
import RichTextInput from 'ra-input-rich-text';
import Quill from "quill";

const sizes = []
for (let i = 4; i <= 100; i++) {
    sizes.push(i + 'px');
}

export const ContentRichTextInput = ({size, ...props}) => {
    const Font = Quill.import('formats/font');
    Font.whitelist = ['', 'alfaSlabOne', 'arsenalBold', 'arsenalRegular', 'delaGothicOne', 'gluten', 'jura', 'luckiestGuy', 'redRoseLight', 'redRoseRegular', 'redRoseBold', 'russoOne', 'seymourOne', 'sigmarOne', 'ultra', 'turtles'];
    Quill.register(Font, true);
    const Size = Quill.import('attributors/style/size');
    Size.whitelist = sizes
    Quill.register(Size, true);

    const toolbarOptions = [
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote', 'code-block'],
        ['link'],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'list': 'check'}],
        [{'script': 'sub'}, {'script': 'super'}],
        [{'indent': '-1'}, {'indent': '+1'}],
        [{'size': Size.whitelist}],
        [{'header': [1, 2, 3, 4, 5, 6, false]}],

        [{'color': []}, {'background': []}],
        [{'font': Font.whitelist}],
        [{'align': []}],

        ['clean']
    ];
    return <RichTextInput
        label="Content"
        options={{
            theme: 'snow',
        }}
        toolbar={toolbarOptions}
        source="content"
        {...props}
    />
};
