import {Button, CreateButton, ExportButton, sanitizeListRestProps, TopToolbar, useListContext} from "react-admin";
import React, {cloneElement, useState} from "react";
import ResetIcon from "@material-ui/icons/Refresh";
import ResetAllDevicesDialog from "./ResetAllDevicesDialog";
import {Snackbar} from "@material-ui/core";

export const UserListActions = (props) => {
    const [state, setState] = useState({open: false, message: ""});

    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props;
    const {
        currentSort,
        resource,
        displayedFilters,
        filterValues,
        hasCreate,
        basePath,
        showFilter,
        total,
    } = useListContext();
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <Button color="primary"
                    onClick={() => setState({open: true, showMessage: false, message: ""})}
                    label={"Reset All Devices"}>
                <ResetIcon/>
            </Button>
            {hasCreate && <CreateButton basePath={basePath}/>}
            <ExportButton
                disabled={total === 0}
                resource={resource}
                sort={currentSort}
                filterValues={filterValues}
                maxResults={maxResults}/>
            <ResetAllDevicesDialog open={state.open}
                                   onResponse={(message, open) => {
                                       setState({open: open, message: message})
                                   }}
            />
            <Snackbar open={state.message != null && state.message.length > 0}
                      message={state.message}
                      autoHideDuration={5000}
                      onClose={() => {
                          setState({open: false, message: ""})
                      }}/>
        </TopToolbar>
    );
};