import React, {cloneElement} from "react";
import Icon from "@material-ui/icons/Category";
import {
    ArrayInput,
    AutocompleteInput,
    Button,
    Create,
    CreateButton,
    Datagrid,
    DeleteWithConfirmButton,
    Edit,
    EditButton,
    ExportButton,
    Filter,
    FormTab,
    ImageField,
    ImageInput,
    List,
    maxLength,
    NumberInput,
    ReferenceField,
    ReferenceInput,
    ReferenceManyField,
    required,
    sanitizeListRestProps,
    SelectInput,
    Show,
    ShowButton,
    SimpleFormIterator,
    Tab,
    TabbedForm,
    TabbedShowLayout,
    TextField,
    TextInput,
    TopToolbar,
    useListContext,
} from "react-admin";
import {TimeField} from "./common/TimeField";
import {VideoGrid} from "./Video";
import {Link as RouterLink} from 'react-router-dom';
import OrderIcon from '@material-ui/icons/ViewQuilt';
import {Field} from "react-final-form";
import ImageUpload from "./common/ImageUpload";

export const CategoryIcon = Icon;
export const CategoryCreate = (props) => (
    <Create {...props}>
        <TabbedForm redirect={"list"}>
            <FormTab label={"resources.categories.general"}>
                <SelectInput source={"type"} choices={Types} validate={required()}/>
                <TextInput source={"name"}
                           multiline
                           validate={[required(), maxLength(255)]}/>
                <NumberInput source={"order"}
                             helperText={'resources.videos.fields.orderHelper'}
                             validate={required()}/>
                <TextInput source={"description"}
                           fullWidth
                           multiline
                           validate={[required(), maxLength(1000000)]}/>
                <ReferenceInput source={"parentId"}
                                filterToQuery={text => ({description: text})}
                                reference={'categories'}>
                    <AutocompleteInput
                        matchSuggestion={matchCategory}
                        optionText={categoryText}/>
                </ReferenceInput>
            </FormTab>
            <FormTab label={"resources.categories.subs"}>
                <ArrayInput source="subs">
                    <SimpleFormIterator>
                        <TextInput source={"name"}
                                   multiline
                                   validate={[required(), maxLength(255)]}/>
                        <TextInput source={"description"}
                                   fullWidth
                                   multiline
                                   validate={[required(), maxLength(1000000)]}/>
                        <ImageInput
                            validate={required()}
                            maxSize={1024 * 5 * 1024}
                            source={"image"}>
                            <ImageField source="src" title="title"/>
                        </ImageInput>
                    </SimpleFormIterator>
                </ArrayInput>
            </FormTab>
            <FormTab label={"resources.categories.fields.image"}>
                <ImageInput name={"image"}
                            validate={required()}
                            maxSize={1024 * 5 * 1024}
                            source={"image"}>
                    <ImageField source="src" title="title"/>
                </ImageInput>
            </FormTab>
        </TabbedForm>
    </Create>
);

export const Types = [
    {id: "FITNESS", name: "resources.categories.types.fitness"},
    {id: "FOOD", name: "resources.categories.types.food"},
    {id: "THIRD", name: "resources.categories.types.third"},
    {id: "HOME", name: "resources.categories.types.home"}

];
export const TypesID = [
    {id: 0, name: "resources.categories.types.fitness"},
    {id: 1, name: "resources.categories.types.food"},
    {id: 2, name: "resources.categories.types.third"},
    {id: 3, name: "resources.categories.types.home"}


];
export const matchCategory = (text, category) => {
    if (category == null || text == null) {
        return false
    }
    if (category.name != null && category.name.includes(text)) {
        return true
    }
    if (category.description != null && category.description.includes(text)) {
        return true
    }


}
export const categoryText = category => category != null && category.name !== undefined ? `${category.name} | ${category.description}` : 'NONE';
export const CategoryEdit = (props) => (
    <Edit {...props}>
        <TabbedForm redirect={"list"}>
            <FormTab label={"resources.categories.general"}>
                <SelectInput source={"type"} choices={Types} validate={required()}/>
                <TextInput source={"name"}
                           multiline
                           validate={[required(), maxLength(255)]}/>
                <NumberInput source={"order"}
                             helperText={'resources.videos.fields.orderHelper'}
                             validate={required()}/>
                <TextInput source={"description"}
                           fullWidth
                           multiline
                           validate={[required(), maxLength(1000000)]}/>
                <ReferenceInput source={"parentId"}
                                filterToQuery={text => ({description: text})}
                                reference={'categories'}>
                    <AutocompleteInput
                        matchSuggestion={matchCategory}
                        optionText={categoryText}/>
                </ReferenceInput>
            </FormTab>
            <FormTab label={"resources.categories.fields.image"}>
                <Field name={"image"}
                       validate={required()}
                       component={ImageUpload}
                       source={"image"}/>
            </FormTab>
        </TabbedForm>
    </Edit>
);

const CategoryFilters = (props) => (
    <Filter {...props}>
        <TextInput source={'name'} alwaysOn/>
        <TextInput source={'description'} alwaysOn/>
        <SelectInput source={'type'} choices={TypesID}/>
        <ReferenceInput source={"parentId"}
                        filterToQuery={text => ({name: text})}
                        reference={'categories'}>
            <AutocompleteInput optionText={categoryText}/>
        </ReferenceInput>
    </Filter>
);
const ListActions = (props) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props;
    const {
        currentSort,
        resource,
        displayedFilters,
        filterValues,
        hasCreate,
        basePath,
        showFilter,
        total,
    } = useListContext();
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <Button color="primary"
                    label={"Rearrange Order"}
                    component={RouterLink} to="/categoriesReorder">
                <OrderIcon/>
            </Button>
            {hasCreate && <CreateButton basePath={basePath}/>}
            <ExportButton
                disabled={total === 0}
                resource={resource}
                sort={currentSort}
                filterValues={filterValues}
                maxResults={maxResults}/>
        </TopToolbar>
    );
};

export const CategoryList = (props) => (
    <List {...props} bulkActionButtons={false}
          actions={<ListActions/>}
          filters={<CategoryFilters/>}>
        <Datagrid>
            <ImageField source={'image'}/>
            <TextField source={"type"}/>
            <ReferenceField source={"parentId"} reference={'categories'}>
                <TextField source={'description'}/>
            </ReferenceField>
            <TextField source={"description"}/>
            <ShowButton/>
            <EditButton/>
            <DeleteWithConfirmButton/>
        </Datagrid>
    </List>
);
const CategoryShowActions = ({basePath, data, resource}) => (
    <TopToolbar>
        <EditButton basePath={basePath} record={data}/>
        {data !== undefined && <Button color="primary"
                                       label={"Rearrange Order"}
                                       category={data}
                                       component={RouterLink} to={`/itemsReorder/${data.id}`}>
            <OrderIcon/>
        </Button>}
    </TopToolbar>
);

export const CategoryShow = (props) => (
    <Show {...props} actions={<CategoryShowActions/>}>
        <TabbedShowLayout>
            <Tab label={'resources.categories.general'}>
                <TextField source={"name"}/>
                <TextField source={"description"}/>
                <TextField source={"type"}/>
                <TimeField source={"time"}/>
                <ImageField source={"image"}/>
            </Tab>
            <Tab label={'resources.videos.name'}>
                <ReferenceManyField target={'categoryId'} reference={'videos'} addLabel={false}>
                    <VideoGrid/>
                </ReferenceManyField>
            </Tab>
        </TabbedShowLayout>
    </Show>
);

export const CategoryTranslations = {
    en: {
        categories: {
            name: "Categories",
            general: "General",
            subs: "Sub categories",
            fields: {
                subName: "Name",
                subDescription: "Description",
                subImage: "Image",
                name: "Name",
                title: "Title",
                image: "Image",
                description: "Description",
                type: "Type",
                time: "Time",
                order: "Order",
                orderHelper: "Higher order will appear in bottom",
            },
            types: {
                fitness: "Fitness",
                food: "Food",
                third: "Third",
                home: "Home",
            }
        },
    },
    tr: {
        categories: {
            name: "Kategoriler",
            general: "General",
            subs: "Sub categories",
            fields: {
                subName: "Name",
                subDescription: "Description",
                subImage: "Image",
                name: "Name",
                title: "Title",
                image: "Image",
                description: "Description",
                type: "Type",
                time: "Time",
                order: "Order",
                orderHelper: "Higher order will appear in bottom",
            },
            types: {
                fitness: "Fitness",
                food: "Food",
                third: "Third",
                home: "Home",
            }
        }
    }
};