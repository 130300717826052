import React from 'react';
import Card from '@material-ui/core/Card';
import CommentIcon from '@material-ui/icons/Receipt';
import {translate} from 'react-admin';
import Icon from '@material-ui/icons/Message';
import CardHeader from "@material-ui/core/CardHeader";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';

const styles = {
    titleLink: {textDecoration: 'none', color: '#000'},
    card: {borderLeft: 'solid 4px #f44336', flex: 1, margin: '1em', height: 300},
    icon: {float: 'right', width: 64, height: 64, padding: 16, color: '#3F51B5'},
};
export default translate(({notifications, translate}) => (
    <Card style={styles.card}>
        <CardHeader avatar={<Avatar> <CommentIcon/> </Avatar>}
                    title={<h3>{translate("previousNotifications.title")}</h3>}/>
        {(notifications !== undefined && Array.isArray(notifications)) ?
            <List style={{height: 240, overflow: 'auto'}}>
                {notifications.map(record => <ListItem>
                    <Avatar>
                        <Icon/>
                    </Avatar>
                    <div style={{marginLeft: "20px"}}>
                        <ListItemText primary={record.subject} secondary={record.body}/>
                    </div>
                </ListItem>)}
            </List> :
            <h3 style={{textAlign: 'center'}}>{translate("previousNotifications.data")}</h3>}
    </Card>));
export const PreviousNotificationsTranslation = {
    en: {
        previousNotifications: {
            title: "Previous Notifications",
            data: "No notifications"
        }
    },
    tr: {
        previousNotifications: {
            title: "Gönderilen bildirimler",
            data: "Bildirim yok"
        }
    },
};
