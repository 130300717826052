import englishMessages from 'ra-language-english';
import {ImageUploadTranslation} from "../common/ImageUpload";
import {UserTranslations} from "../user/User";
import {PreviousNotificationsTranslation} from "../dashboard/PreviousNotifications";
import {SendNotificationTranslations} from "../dashboard/SendNotification";
import {VideoTranslations} from "../Video";
import {CategoryTranslations} from "../Category";
import {FaqTranslations} from "../Faq";
import {UserResetTranslations} from "../user/ResetDeviceView";
import {UserResetPasswordTranslations} from "../user/ResetPasswordView";
import {ImageTranslations} from "../Images";
import {NotificationTranslations} from "../notification/Notification";
import {ResetAllDevicesViewTranslations} from "../user/ResetAllDevicesDialog";
import {PdfTranslations} from "../Pdf";
import {TextTranslations} from "../Text";

export default {
    ...englishMessages,
    ...ImageUploadTranslation.en,
    login: {
        loginFailed: "Login failed. Check username or password"
    },

    resources: {
        ...UserTranslations.en,
        ...VideoTranslations.en,
        ...CategoryTranslations.en,
        ...FaqTranslations.en,
        ...ImageTranslations.en,
        ...NotificationTranslations.en,
        ...PdfTranslations.en,
        ...TextTranslations.en,
    },
    ...PreviousNotificationsTranslation.en,
    ...SendNotificationTranslations.en,
    ...UserResetTranslations.en,
    ...UserResetPasswordTranslations.en,
    ...ResetAllDevicesViewTranslations.en,

}