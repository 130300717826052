import React from "react";
import SendIcon from '@material-ui/icons/Refresh';
import PasswordIcon from '@material-ui/icons/Lock';
import {Button, createMuiTheme, DialogActions, MuiThemeProvider, Snackbar} from "@material-ui/core";
import {translate} from 'react-admin';
import ResetDeviceView from "./ResetDeviceView";
import {lightTheme} from "../layout/Layout";
import ResetPasswordView from "./ResetPasswordView";

class UserShowActions extends React.Component {
    state = {open: false};

    render() {
        const {data, translate} = this.props;
        const {showMessage, message, open, openPassword} = this.state;
        return (
            <DialogActions>
                {data !== undefined && <Button variant={'text'}
                                               color={'primary'}
                                               onClick={() => this.setState({open: true})}>
                    <SendIcon/> {translate("users.resetDevice.reset")}
                </Button>}
                {data !== undefined && <Button variant={'text'}
                                               color={'primary'}
                                               onClick={() => this.setState({openPassword: true})}>
                    <PasswordIcon/> {translate("resetPassword.reset")}
                </Button>}
                <ResetDeviceView open={open}
                                 onResponse={(message, open) => {
                                     this.setState({showMessage: message !== null, message: message, open: open})
                                 }}
                                 record={data}/>
                <ResetPasswordView open={openPassword}
                                   onResponse={(message, open) => {
                                       this.setState({
                                           showMessage: message !== null,
                                           message: message,
                                           openPassword: open
                                       })
                                   }}
                                   record={data}/>
                <Snackbar open={showMessage}
                          message={message}
                          autoHideDuration={5000}
                          onClose={() => {
                              this.setState({showMessage: false})
                          }}/>
            </DialogActions>
        );
    }
}

const Themed = props =>
    <MuiThemeProvider theme={createMuiTheme(lightTheme)}>
        <UserShowActions {...props}/>
    </MuiThemeProvider>;
export default translate(Themed);