import React from 'react';
import {Button, Dialog, DialogActions} from "@material-ui/core";
import SendIcon from '@material-ui/icons/Refresh';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import {fetchJson} from "../rest/fetch";
import {SERVER_IP} from "../index";
import CircularProgress from "@material-ui/core/CircularProgress";
import {translate} from 'react-admin';
import TextField from "@material-ui/core/TextField";

class ResetPasswordView extends React.Component {
    state = {
        password: "",
        confirmPassword: ""
    };

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({passwordMatchError: false});
    };


    handleSend = () => {

        const {record} = this.props;
        if (record === undefined) {
            return
        }
        const pass = this.state.password;
        const confirmPassword = this.state.confirmPassword;
        if (pass === null || pass.length < 3) {
            this.setState({errorPassword: true});
            return;
        }
        if (confirmPassword !== pass) {
            this.setState({passwordMatchError: true});
            return;
        }
        this.setState({loading: true});

        fetchJson(`${SERVER_IP}/users/resetPassword/${record.id}?password=${pass}`,
            {method: "PUT"})
            .then(() => {
                this.setState({
                    loading: false,
                    password: "",
                    confirmPassword: "",
                    errorPassword: false,
                    passwordMatchError: false
                });
                this.props.onResponse(this.props.translate("resetPassword.done"), false);
            }, () => {
                this.setState({loading: false, errorPassword: false, passwordMatchError: false});
                this.props.onResponse(this.props.translate("resetPassword.failed"), false);
            })

    };
    handleChange = name => (event) => {
        if (name === 'password') {
            let password = this.state.confirmPassword;
            let val = event.target.value;
            this.setState({
                errorPassword: val.length < 3,
                password: val,
                passwordMatchError: password !== val
            });
        } else if (name === 'confirmPassword') {
            let password = this.state.password;
            let val = event.target.value;
            this.setState({passwordMatchError: password !== val, confirmPassword: event.target.value});
        }
    };

    render() {
        const {loading, passwordMatchError, password, confirmPassword, errorPassword} = this.state;
        const {translate, open, record} = this.props;
        let title = translate("resetPassword.resetTitle");
        if (record !== undefined) {
            title = title + record.name;
        }
        return (
            <Dialog open={open}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title">
                <DialogTitle
                    id="form-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {translate("resetPassword.resetMessage")}
                    </DialogContentText>
                    <TextField
                        error={errorPassword}
                        style={{marginTop: '1em', marginBottom: '1em'}}
                        id={"password"}
                        label={translate("resetPassword.password")}
                        value={password}
                        helperText={errorPassword ? translate("resetPassword.lengthError") : undefined}
                        disabled={loading}
                        onChange={this.handleChange('password')}
                        required/>
                    <TextField
                        error={passwordMatchError}
                        style={{marginBottom: '1em'}}
                        id={"confirmPassword"}
                        label={translate("resetPassword.confirmPassword")}
                        value={confirmPassword}
                        fullWidth
                        required
                        helperText={passwordMatchError ? translate("resetPassword.passwordNotMatch") : undefined}
                        disabled={loading}
                        onChange={this.handleChange('confirmPassword')}/>
                </DialogContent>
                <DialogActions>
                    <Button disabled={loading}
                            variant={'text'} color={'primary'}
                            onClick={() => {
                                this.props.onResponse(null, false);
                            }}>
                        {translate("resetPassword.cancel")}
                    </Button>
                    <Button disabled={loading} variant={'text'}
                            color={'primary'}
                            onClick={this.handleSend}>
                        {loading && (
                            <CircularProgress size={25} thickness={2}/>
                        )}
                        <SendIcon/>{translate("resetPassword.reset")}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export const UserResetPasswordTranslations = {
    en: {
        resetPassword: {
            resetTitle: "Reset password of ",
            resetMessage: "This will reset user password. Are you sure to reset?",
            cancel: "Cancel",
            password: "Password",
            confirmPassword: "Confirm Password",
            passwordNotMatch: "Password does not match",
            done: "Password reset successful",
            failed: "Failed to reset password.",
            reset: "Reset Password",
            lengthError: "Please put at least 3 characters"
        }
    },
    tr: {
        resetPassword: {
            resetTitle: "Reset password of ",
            resetMessage: "This will reset user password. Are you sure to reset?",
            cancel: "Cancel",
            password: "Password",
            confirmPassword: "Confirm Password",
            passwordNotMatch: "Password does not match",
            done: "Password reset successful",
            failed: "Failed to reset password.",
            reset: "Reset Password",
            lengthError: "Please put at least 3 characters"
        }
    }
};

export default translate(ResetPasswordView);