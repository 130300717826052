import React from "react";
import {
    AutocompleteInput,
    CloneButton,
    Create,
    Datagrid,
    DeleteWithConfirmButton,
    FormTab,
    ImageField,
    List,
    maxLength,
    ReferenceInput,
    required,
    Show,
    ShowButton,
    SimpleShowLayout,
    TabbedForm,
    TextField,
    TextInput,
} from 'react-admin';
import {TimeField} from "../common/TimeField";
import ImageUpload from "../common/ImageUpload";
import {Field} from 'react-final-form';
import {categoryText, matchCategory} from "../Category";
import {DateTimePickerInput} from "../common/DateTimePickerInput";

export const NotificationsList = props => (
    <List {...props} sort={{field: 'time', order: 'DESC'}}>
        <Datagrid>
            <TextField source={'id'}/>
            <TextField source={'subject'}/>
            <TextField source={'body'}/>
            <TimeField source={'time'}/>
            <CloneButton/>
            <ShowButton/>
            <DeleteWithConfirmButton/>
        </Datagrid>
    </List>
);

export const NotificationCreate = props => (
    <Create {...props} >
        <TabbedForm redirect={'list'}>
            <FormTab label={"Text"}>
                <TextInput source={"subject"}
                           validate={[required(), maxLength(255)]}/>
                <TextInput source={"body"}
                           fullWidth
                           multiline
                           validate={[required(), maxLength(1_000_000)]}/>
                <ReferenceInput source={"categoryId"}
                                allowEmpty
                                filterToQuery={text => ({description: text})}
                                reference={'categories'}>
                    <AutocompleteInput
                        allowEmpty
                        matchSuggestion={matchCategory}
                        resettable
                        optionText={categoryText}/>
                </ReferenceInput>
                <ReferenceInput source={"videoId"}
                                allowEmpty
                                filterToQuery={text => ({name: text})}
                                reference={'videos'}>
                    <AutocompleteInput optionText={categoryText}
                                       resettable
                                       allowEmpty/>
                </ReferenceInput>
                <ReferenceInput source={"imageId"}
                                allowEmpty
                                filterToQuery={text => ({name: text})}
                                reference={'images'}>
                    <AutocompleteInput optionText={categoryText}
                                       resettable
                                       allowEmpty
                                       helperText={'resources.notifications.helperText'}/>
                </ReferenceInput>
                <Field name={"scheduleTime"}
                       source={'scheduleTime'}
                       label={"Schedule At"}
                       helperText={"Notification will be sent on selected time. If selected time is ± 5 minutes of current time then notification will be send immediately"}
                       component={DateTimePickerInput}/>
            </FormTab>
            <FormTab label={"Image"}>
                <Field name={"image"}
                       component={ImageUpload}
                       source={"image"}/>
            </FormTab>
        </TabbedForm>
    </Create>
)

export const NotificationShow = props => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source={"subject"}/>
            <TextField source={"body"}/>
            <ImageField source={'image'}/>
        </SimpleShowLayout>
    </Show>
);


export const NotificationTranslations = {
    en: {
        notifications: {
            name: "Notifications",
            helperText: "You can select either category or video or image. If you selected category, on notification click it " +
                "will open category screen if you selected video it will open video player screen. You may not select any of these.",
            fields: {
                id: "#",
                subject: "Subject",
                body: "Body",
                image: "Image",
                categoryId: "Category",
                videoId: "Video",
            }
        }
    },
    tr: {
        notifications: {
            name: "Notifications",
            helperText: "You can select either category or video or image. If you selected category, on notification click it " +
                "will open category screen if you selected video it will open video player screen. You may not select any of these.",
            fields: {
                id: "#",
                subject: "Subject",
                body: "Body",
                image: "Image",
                categoryId: "Category",
                videoId: "Video",
            }
        }
    }
}