import React from 'react';

import englishMessages from './i18n/en';
import turkishMessages from './i18n/tr';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import {Admin, resolveBrowserLocale, Resource} from 'react-admin';
import AuthClient from "./login/AuthClient";
import {SERVER_IP} from "./index";
import OurClient from "./rest/OurClient";
import CustomLayout from "./layout/Layout";
import Login from "./login/Login"
import {CategoryCreate, CategoryEdit, CategoryList, CategoryShow} from "./Category";
import {VideoCreate, VideoEdit, VideoList, VideoShow} from "./Video";
import {UserCreate, UserEdit, UserList, UserShow} from "./user/User";
import {FaqCreate, FaqEdit, FaqList} from "./Faq";
import routes from "./routes";
import {ImageCreate, ImageEdit, ImageList} from "./Images";
import {NotificationCreate, NotificationShow, NotificationsList} from "./notification/Notification";
import {PdfCreate, PdfEdit, PdfList, PdfShow} from "./Pdf";
import {TextCreate, TextEdit, TextList, TextShow} from "./Text";

const messages = {
    en: {...englishMessages},
    tr: {...turkishMessages}
};

export const locale = () => {
    let loc = resolveBrowserLocale();
    if (loc !== 'tr' && loc !== 'en') {
        return 'tr';
    } else {
        return loc;
    }
};

const i18nProvider = polyglotI18nProvider(() => messages[locale()], 'tr');

function App() {
    return (
        <Admin dataProvider={OurClient(SERVER_IP)}
               authProvider={AuthClient}
               loginPage={Login}
               customRoutes={routes}
               title={"Fitness Web Panel"}
               i18nProvider={i18nProvider}
               layout={CustomLayout}>

            <Resource name={"categories"}
                      list={CategoryList}
                      edit={CategoryEdit}
                      show={CategoryShow}
                      create={CategoryCreate}/>
            <Resource name={"pdfs"}
                      list={PdfList}
                      edit={PdfEdit}
                      show={PdfShow}
                      create={PdfCreate}/>
            <Resource name={"texts"}
                      list={TextList}
                      edit={TextEdit}
                      show={TextShow}
                      create={TextCreate}/>
            <Resource name={'videos'}
                      edit={VideoEdit}
                      show={VideoShow}
                      list={VideoList}
                      create={VideoCreate}/>

            <Resource name={'users'}
                      list={UserList}
                      edit={UserEdit}
                      show={UserShow}
                      create={UserCreate}/>

            <Resource name={'images'}
                      edit={ImageEdit}
                      create={ImageCreate}
                      list={ImageList}/>
            <Resource name={'faqs'}
                      edit={FaqEdit}
                      create={FaqCreate}
                      list={FaqList}/>
            <Resource
                name={'notifications'}
                show={NotificationShow}
                create={NotificationCreate}
                list={NotificationsList}/>
        </Admin>
    );
}

export default App;
