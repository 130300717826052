import React from "react";
import Icon from '@material-ui/icons/PhotoAlbum';
import ImageUpload from "./common/ImageUpload";
import {
    ArrayInput,
    AutocompleteInput,
    BooleanField,
    BooleanInput,
    Create,
    Datagrid,
    DeleteWithConfirmButton,
    Edit,
    EditButton,
    Filter,
    FormDataConsumer,
    FormTab,
    ImageField,
    List,
    ReferenceField,
    ReferenceInput,
    required,
    SelectInput,
    SimpleFormIterator,
    TabbedForm,
    TextField,
    TextInput
} from 'react-admin';
import {Field} from 'react-final-form';
import {categoryText, matchCategory, Types} from "./Category";

export const ImageIcon = Icon;

const CreateOrEdit = (props) => (
    <TabbedForm {...props} redirect={'list'}>
        <FormTab label={"General"}>
            <TextInput source={'name'} validate={required()}/>
            <ReferenceInput source={"category.id"}
                            allowEmpty
                            filterToQuery={text => ({description: text})}
                            reference={'categories'}>
                <AutocompleteInput
                    matchSuggestion={matchCategory}
                    optionText={categoryText}/>
            </ReferenceInput>
            <SelectInput source={"type"} choices={Types} validate={required()}/>
            <BooleanInput source={'featured'}
                          options={{helperText: "Featured images appear in slider on home screen"}}/>
            <TextInput source={'description'}/>
        </FormTab>
        <FormTab label={"resources.images.fields.preview"}>
            <Field name={'preview'}
                   source={'preview'}
                   component={ImageUpload}/>
        </FormTab>
        <FormTab label={"resources.images.fields.url"}>
            <Field name={"url"}
                   validate={required()}
                   component={ImageUpload}
                   source={"url"}/>
            <ArrayInput source={'images'}>
                <SimpleFormIterator>
                    <TextInput source={'index'} validate={required()}/>
                    <FormDataConsumer>
                        {({getSource, scopedFormData}) => {
                            return (
                                <Field name={getSource("image")}
                                       record={scopedFormData}
                                       component={ImageUpload}
                                       source={getSource("image")}/>);
                        }}
                    </FormDataConsumer>

                </SimpleFormIterator>
            </ArrayInput>
        </FormTab>

    </TabbedForm>
);
const ImageFilters = (props) => (
    <Filter {...props}>
        <TextInput source={'name'} alwaysOn/>
        <TextInput source={'description'} alwaysOn/>
        <BooleanField source={'featured'}/>
        <ReferenceInput source={"category.id"}
                        filterToQuery={text => ({description: text})}
                        reference={'categories'}>
            <AutocompleteInput
                matchSuggestion={matchCategory}
                optionText={categoryText}/>
        </ReferenceInput>
    </Filter>
);
export const ImageList = (props) => (
    <List {...props} bulkActionButtons={false} filters={<ImageFilters/>}>
        <Datagrid>
            <ImageField source={'url'}/>
            <TextField source={"type"}/>
            <TextField source={'description'}/>
            <BooleanField source={'featured'}/>
            <ReferenceField source={"category.id"} reference={'categories'}>
                <TextField source={'description'}/>
            </ReferenceField>}
            <EditButton/>
            <DeleteWithConfirmButton/>
        </Datagrid>
    </List>
);

export const ImageCreate = (props) => (
    <Create {...props}>
        <CreateOrEdit/>
    </Create>
);

export const ImageEdit = (props) => (
    <Edit {...props}>
        <CreateOrEdit/>
    </Edit>
);

export const ImageTranslations = {
    en: {
        images: {
            name: "Images",
            fields: {
                url: "Image",
                index: "Position",
                featured: "Featured",
                preview: "Thumbnail",
                name: 'Name',
                category: {
                    id: "Category",
                }
            }
        }
    },
    tr: {
        images: {
            name: "Fotografler",
            fields: {
                url: "Resim",
                index: "Position",
                name: "Name",
                preview: "Thumbnail",
                featured: "Featured",
                category: {
                    id: "Category",
                }
            }
        }
    }
};