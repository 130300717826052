import React, {useState} from "react";
import {useMountEffect} from "./Util";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    MenuItem,
    MuiThemeProvider,
    Select
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {Title} from 'react-admin';
import {arrayMove, SortableContainer, SortableElement} from 'react-sortable-hoc';
import Button from "@material-ui/core/Button";
import {createMuiTheme} from '@material-ui/core/styles';
import {lightTheme} from "./layout/Layout";
import {SERVER_IP} from "./index";
import {useParams} from 'react-router-dom'

const useStyles = makeStyles((theme) => ({

    paper: {
        margin: 10,
        userSelect: 'none',
        borderRadius: 10,
    },
    titleDiv: {
        marginTop: 10,
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
        background: theme.palette.secondary.main,
    },
    iconDiv: {
        height: 150,
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
    },
    title: {
        fontSize: 'medium',
        fontWeight: "bold",
        lineHeight: '50px',
        color: 'white',
        textOverflow: "ellipsis",
        overflow: 'hidden',
        maxHeight: 50,
        display: 'block',
    },
    paperDiv: {
        textAlign: 'center',
        alignContent: 'center'
    },
    icon: {
        width: 100,
        height: 100,
        pointerEvents: 'none',
    },
    pageTitle: {
        textAlign: 'center',
        height: 100,
    },
    select: {
        margin: '1em',
        textAlign: "center",
        minWidth: '200',
    }
}));

const gridStyles = {
    display: 'grid',
    gridTemplateColumns: 'repeat(6, 1fr)',
    gridGap: '16px',
};

const gridItemStyles = {
    height: '300px',
};
const categoryTypes = ["FOOD", "FITNESS", "THIRD", "HOME"];

function CategoriesReorder() {
    const classes = useStyles();
    const {categoryId} = useParams();
    const [type, setType] = useState("FOOD");
    const [departments, setDepartments] = useState([]);
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [message, setMessage] = useState({text: "", state: false});

    const loadData = ((type) => {
        setLoading(true);
        let headers = new Headers();
        headers.append("Authorization", `Bearer ${localStorage.getItem('token')}`);
        const abortController = new AbortController();
        let aborted = false;
        let url = `${SERVER_IP}/categories/getSeq`;
        if (categoryId === undefined) {
            url = `${url}?type=${type}`;
        } else {
            url = `${url}?categoryId=${categoryId}`;
        }
        fetch(url, {
            headers: headers,
            signal: abortController.signal
        })
            .then(response => {
                return response.json();
            })
            .then(data => {
                setLoading(false);
                if (Array.isArray(data)) {
                    setDepartments(data);
                }
            })
            .catch(e => {
                if (!aborted) {
                    setLoading(false);
                }
            });
        return function cleanup() {
            aborted = true;
            abortController.abort();
        }
    });
    useMountEffect(() => loadData(type))

    const onSortEnd = ({oldIndex, newIndex}) => {
        departments[oldIndex].seq = newIndex;
        const deps = arrayMove(departments, oldIndex, newIndex);
        setDepartments(deps);
    };

    const onSave = () => {
        setSaving(true);
        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", `Bearer ${localStorage.getItem('token')}`);
        let body = departments.map((dep, index) => {
            dep.seq = index;
            return dep;
        });
        fetch(`${SERVER_IP}/categories/updateSeq`,
            {method: "POST", headers: headers, body: JSON.stringify(body)})
            .then(response => {
                return response.json();
            })
            .then(() => {
                setMessage({text: "Sequence updated successfully.", state: true})
                setSaving(false);
            })
            .catch(e => {
                setMessage({text: `Failed to update sequence because ${e}`, state: true})
                setSaving(false);
            });
    }

    const onTypeChange = newType => {
        if (newType.target.value !== type) {
            setType(newType.target.value);
            loadData(newType.target.value);
        }
    }

    return <Card>
        <Title title="Arrange Items"/>

        <CardContent>
            <div className={classes.pageTitle}>
                <span>Drag to arrange items {categoryId !== undefined && `in #${categoryId}`}</span>
                <br/>
                {categoryId === undefined ? <Select multiple={false} native={false}
                                                    value={type}
                                                    className={classes.select}
                                                    onChange={onTypeChange}>
                    {categoryTypes
                        .map(item => <MenuItem key={item} value={item}>{item}</MenuItem>)}
                </Select> : <span/>}
                <br/>
                {loading && <CircularProgress size={24}/>}
            </div>

            <div style={{height: 600, overflow: 'scroll'}}>
                {!loading && <SortableGrid departments={departments}
                                           onSortEnd={onSortEnd}
                                           axis={'xy'}
                                           distance={10}
                                           useWindowAsScrollContainer={true}
                                           helperClass='sortableHelper'
                                           classes={classes}/>}
            </div>
            <Button variant={'contained'}
                    disabled={saving}
                    size={'large'}
                    onClick={onSave}
                    color={'secondary'}>
                Save
                {saving && <CircularProgress size={20}/>}
            </Button>
        </CardContent>
        <Dialog open={message.state}>
            <DialogContent>
                <DialogContentText>
                    {message.text}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setMessage({state: false, text: message.text})}
                        color={'secondary'}>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    </Card>
}

const SortableGrid = SortableContainer(({departments, classes}) => (
    <div style={gridStyles}>
        {departments.map((tile, index) => (
            <DraggableTile tile={tile}
                           cols={1}
                           key={`${tile.type}-${tile.id}`}
                           index={index}
                           classes={classes}/>
        ))}
    </div>
));

const DraggableTile = SortableElement(({tile, classes}) => (
    <div style={gridItemStyles}>
        <Paper className={classes.paper}>
            <div className={classes.paperDiv}>
                <div className={classes.iconDiv}>
                    <img className={classes.icon} src={tile.image} alt={tile.title}/>
                </div>
                <div className={classes.titleDiv}>
                    <span className={classes.title}>{tile.title}</span>
                </div>
            </div>
        </Paper>
    </div>
));

const CategoryReOrderTheme = props => (
    <MuiThemeProvider theme={createMuiTheme(lightTheme)}>
        <CategoriesReorder {...props} />
    </MuiThemeProvider>
);

export default CategoryReOrderTheme;