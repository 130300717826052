import React, {useState} from 'react';
import {Field, Form} from 'react-final-form';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {lightTheme} from "../layout/Layout";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";

import {Notification, useLogin, useNotify, useTranslate} from 'react-admin';
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import bg from '../images/logo.png';
import {makeStyles, MuiThemeProvider} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundImage: `url(${bg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '500px',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    fields: {
        margin: theme.spacing(3, 0, 2)
    }
}));

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="#">
                Fitness
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const renderInput = ({
                         meta: {touched, error} = {},
                         input: {...inputProps},
                         ...props
                     }) => (
    <TextField
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
    />
);

const Login = ({location}) => {
    const [loading, setLoading] = useState(false);
    const translate = useTranslate();
    const classes = useStyles();
    const notify = useNotify();
    const login = useLogin();
    const handleSubmit = auth => {
        setLoading(true);
        login(auth, location.state ? location.state.nextPathname : '/')
            .then(() => setLoading(false))
            .catch(error => {
                setLoading(false);
                notify(
                    typeof error === 'string'
                        ? error
                        : typeof error === 'undefined' || !error.message
                        ? 'ra.auth.sign_in_error'
                        : error.message,
                    'warning'
                );
            });
    };
    const validate = values => {
        const errors = {};
        if (!values.username) {
            errors.username = translate('ra.validation.required');
        }
        if (!values.password) {
            errors.password = translate('ra.validation.required');
        }
        return errors;
    };

    return (
        <Form onSubmit={handleSubmit} validate={validate} render={({handleSubmit}) => (
            <Grid container component="main" className={classes.root}>
                <CssBaseline/>
                <Grid item xs={false} sm={4} md={7} className={classes.image}/>
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <div className={classes.paper}>
                        <Avatar className={classes.avatar}>
                            <LockOutlinedIcon/>
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            {translate('ra.auth.sign_in')}
                        </Typography>

                        <form className={classes.form}
                              noValidate
                              onSubmit={handleSubmit}>
                            <Field component={renderInput}
                                   variant="outlined"
                                   label={translate('ra.auth.username')}
                                   autoFocus
                                   margin="normal"
                                   name="username"
                                   disabled={loading}
                                   className={classes.fields}
                            />
                            <Field component={renderInput}
                                   disabled={loading}
                                   variant="outlined"
                                   label={translate('ra.auth.password')}
                                   type="password"
                                   id="password"
                                   className={classes.fields}
                                   autoComplete="current-password"
                                   name={"password"}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                disabled={loading}
                                className={classes.submit}
                            >
                                {loading && (
                                    <CircularProgress size={25} thickness={2}/>
                                )}
                                {translate('ra.auth.sign_in')}
                            </Button>

                            <Box mt={5}>
                                <Copyright/>
                            </Box>
                        </form>
                    </div>
                </Grid>
                <Notification/>
            </Grid>
        )}/>);
};


const LoginWithTheme = props => (
    <MuiThemeProvider theme={createMuiTheme(lightTheme)}>
        <Login {...props} />
    </MuiThemeProvider>
);

export default LoginWithTheme;