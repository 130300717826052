import React from "react";
import Icon from '@material-ui/icons/ContactSupport';
import {
    Create,
    Datagrid,
    DeleteWithConfirmButton,
    Edit,
    EditButton,
    List,
    required,
    SimpleForm,
    TextField,
    TextInput
} from 'react-admin';
import RichTextInput from "ra-input-rich-text";

export const FaqIcon = Icon;

const CreateOrEdit = (props) => (
    <SimpleForm {...props} redirect={'list'}>
        <TextInput source={'question'} fullWidth validate={required()}/>
        <RichTextInput source={'answer'} fullWidth validate={required()} multiline/>
    </SimpleForm>
);

export const FaqCreate = (props) => (
    <Create {...props}>
        <CreateOrEdit/>
    </Create>
);

export const FaqEdit = (props) => (
    <Edit {...props}>
        <CreateOrEdit/>
    </Edit>
);

export const FaqList = props => (
    <List {...props} bulkActionButtons={false}>
        <Datagrid>
            <TextField source={'question'}/>
            <EditButton/>
            <DeleteWithConfirmButton/>
        </Datagrid>
    </List>
);

export const FaqTranslations = {
    en: {
        faqs: {
            name: "FAQ",
            fields: {
                question: "Question",
                answer: "Answer"
            }
        },
    },
    tr: {
        faqs: {
            name: "FAQ",
            fields: {
                question: "Question",
                answer: "Answer"
            }
        },
    }
};