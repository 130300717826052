import React from "react";
import {
    BooleanField,
    BooleanInput,
    Create,
    Datagrid,
    DeleteWithConfirmButton,
    Edit,
    EditButton,
    Filter,
    List,
    maxLength,
    minLength,
    PasswordInput,
    required,
    SelectArrayInput, SelectInput,
    Show,
    ShowButton,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput,
} from 'react-admin';
import UserActions from "./UserActions";
import {Types} from "../Category";
import {TimeField} from "../common/TimeField";
import {UserListActions} from "./UserListActions";

const UserFilters = (props) => (
    <Filter {...props}>
        <TextInput source={'name'} alwaysOn/>
        <TextInput source={'username'} alwaysOn/>
        <BooleanInput source={'enabled'}/>
    </Filter>
);
export const UserCreate = (props) => (
    <Create {...props}>
        <SimpleForm redirect={'list'}>
            <TextInput source={"name"}
                       validate={[required(), maxLength(100)]}/>
            <BooleanInput source={"enabled"}/>
            <TextInput source={"username"} validate={[required(), minLength(4), maxLength(30)]}/>
            <PasswordInput source={"password"} validate={[required(), minLength(6), maxLength(30)]}/>
            <SelectInput source={'expireDate'} choices={expireDates}/>
            <SelectArrayInput source={'allowedTypes'} choices={Types} validate={required()}/>
        </SimpleForm>
    </Create>
);

const expireDates = [{id: new Date().getTime() + 30 * 24 * 60 * 60 * 1000, name: "1 Ay"},
    {id: new Date().getTime() + 2 * 30 * 24 * 60 * 60 * 1000, name: "2 Ay"},
    {id: new Date().getTime() + 3 * 30 * 24 * 60 * 60 * 1000, name: "3 Ay"},
    {id: new Date().getTime() + 6 * 30 * 24 * 60 * 60 * 1000, name: "6 Ay"},
    {id:  new Date().getTime() + 12 * 30 * 24 * 60 * 60 * 1000, name: "12 Ay"},
    {id: -1, name: "Süresiz"}
]
export const UserEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source={"name"} validate={required()}/>
            <BooleanInput source={"enabled"}/>
            <TextInput source={"username"}
                       disabled
                       validate={[required(), minLength(4)]}/>
            <PasswordInput source={"password"}
                           disabled
                           validate={[required(), minLength(6)]}/>
            <SelectInput source={'expireDate'} choices={expireDates}/>
            <SelectArrayInput source={'allowedTypes'} choices={Types} validate={required()}/>
            <TextInput source={"description"}
                       fullWidth
                       multiline
                       validate={[required(), maxLength(1000000)]}/>
        </SimpleForm>
    </Edit>
);


export const UserList = (props) => (
    <List {...props} bulkActionButtons={false}
          actions={<UserListActions/>}
          filters={<UserFilters/>} sort={{field: "creationTime", order: "DESC"}}>
        <Datagrid>
            <TextField source={"name"}/>
            <TextField source={"username"}/>
            <BooleanField source={"enabled"}/>
            <TimeField source={"creationTime"}/>
            <TimeField source={"expireDate"}/>
            <ShowButton/>
            <EditButton/>
            <DeleteWithConfirmButton/>
        </Datagrid>
    </List>
);

export const UserShow = (props) => (
    <Show {...props} actions={<UserActions/>}>
        <SimpleShowLayout>
            <TextField source={"name"}/>
            <TextField source={"username"}/>
            <BooleanField source={"enabled"}/>
            <TextField source={'description'}/>
        </SimpleShowLayout>
    </Show>
);
export const UserTranslations = {
    en: {
        users: {
            name: "Users",
            fields: {
                enabled: "Enabled",
                name: "Name",
                username: "Username",
                password: "Password",
                expireDate: "Expires At",
            }
        }
    },
    tr: {
        users: {
            name: "Kullanıcılar",
            fields: {
                enabled: "Aktif",
                name: "İsim",
                username: "Kullanıcı Adı",
                password: "Şifre",
                expireDate: "Süresi"
            }
        }
    }
};